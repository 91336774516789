import { api, successResponseSchema } from '@/api';

/**
 * Deletes an existing cost from a project.
 *
 * @param projectKey - The key of the project.
 * @param costKey - The key of the cost to delete.
 */
export async function deleteProjectCost(projectKey: string, costKey: string) {
  const path = `/v1/project/${projectKey}/costs/${costKey}`;
  const response = await api.delete(path);

  return successResponseSchema.parseAsync(response.data);
}
