import { Customer } from '@/customer/api/types';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import { Avatar, Tooltip } from '@mui/material';
import { useState } from 'react';

interface Props {
  customer: Customer;
  size?: number;
  fallback?: string;
}

const DEFAULT_SIZE = 40;

export function CustomerIcon(props: Props) {
  const [imageError, setImageError] = useState(false);

  const handleError = () => {
    setImageError(true);
  };

  const size = props.size ?? DEFAULT_SIZE;

  if (!props.customer.logoURL || imageError) {
    return <ApartmentRoundedIcon />;
  }

  return (
    <Tooltip arrow title={props.customer.name}>
      <Avatar
        src={props.customer.logoURL}
        alt={`${props.customer.name} logo`}
        sx={{
          border: 0,
          width: size,
          height: '100%',
          objectFit: 'cover',
          backgroundColor: 'white',
        }}
        onError={handleError}
        variant="rounded"
      >
        {props.fallback ?? props.customer.name.charAt(0).toUpperCase()}
      </Avatar>
    </Tooltip>
  );
}
